@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

div.company-filter-container {
    position: relative;

    div.filter-container {
        position: absolute;
        width: 220px;
        left: -187px;
        top: 24px;
        background: #F1EEEA;
        box-shadow: -3px -1px 7px rgba(0, 0, 0, 0.25);
        max-height: 500px;
        z-index: 2;

        div.filter-search-container {
            padding: 10px;

            .filter-search-box {
                display: flex;
                border: 1px solid $gray--500;
                background: white;
                height: 30px;
                border-radius: 2px;

                img {
                    height: 15px;
                    margin: 6px;
                }

                input {
                    font-family: $sans-reg;
                    width: 80%;
                    border: none;
                    outline: none;
                    box-shadow: none;
                }

                input::placeholder {
                    font-size: 12px;
                }
            }
        }

        div.filter-list-container::-webkit-scrollbar {
            width: 5px;
            height: 10px;
        }

        div.filter-list-container {
            position: relative;
            width: 218px;
            background: #F1EEEA;
            max-height: 380px;
            overflow: auto;
            overflow-x: hidden;

            div.grouped-company {
                background: #DFD7CD;
                height: 14px;
                font-size: 10px;
                line-height: 15px;
                font-weight: 700;
                color: $gray--500;
                padding-left: 10px;
            }

            div.filter-item {
                width: 218px;
                min-height: 30px;
                padding: 0px 10px;
                background: #F1EEEA;
                padding-top: 4px;

                .ui.checkbox label::before,
                .ui.checkbox label::after {
                    width: 16px;
                    height: 16px;
                    border: 1px solid $bcggreenfive;
                    border-radius: 0px;
                }

                .ui.checkbox label::after {
                    background: $bcggreenfive;
                    color: $white;
                }

                .ui.checkbox label:hover::before {
                    border: 2px solid $bcggreenfive;
                }

                .ui.checkbox.filter-checkbox label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: $gray--700;
                }
            }
        }

        .filter-separator {
            border: 1px solid $gray--400;
            margin: 6px 10px 0 10px;
        }

        div.filter-action-item {
            height: 44px;
            padding: 8px 12px;

            .btn {
                text-transform: uppercase;
                margin: 0;
                text-decoration: none;
                font-size: 12px;
                font-weight: 400;
                align-items: center;
                justify-content: center;
                text-align: center;
                box-sizing: border-box;
                cursor: pointer;
                display: inline-flex;
                font-style: normal;
                border-radius: 0px;
            }

            .btn:focus {
                box-shadow: none;
            }

            .primary.button {
                width: 110px;
                height: 30px;
                background: $bcggreenfive;
                color: $white;
                border: 0px;
                float: right;
            }

            .primary.button:hover {
                background-color: $bcggreenfivehover;
            }

            .secondary.button {
                color: $bcggreenfive;
                border: 1px solid;
                height: 30px;
            }

            .secondary.button:hover {
                color: $bcggreenfivehover;
            }
        }
    }
}