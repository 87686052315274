@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

div.taxonomy-filter-modal {
    position: relative;
    width: fit-content;

    .header {
        display: flex;
        font-size: 16px;
        color: #323232;
        font-weight: 700;

        .modal-header-text {
            margin: 0 auto;
        }

        img.cross-image {
            height: 8px;
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
        }
    }

    .body {
        padding: 10px 0px;

        .filter-main-content {
            display: flex;
            margin-top: 5px;

            .filter-item-container {
                height: 30px;
                border-bottom: 1px solid;
                padding: 5px;
                cursor: pointer;

                .filter-item {
                    margin-right: 20px;
                    position: relative;

                    .arrow-icon {
                        position: absolute;
                        right: -17px;
                        top: 5px;
                    }

                    .arrow-icon.selected {
                        transform: rotate(90deg);
                    }
                }
            }

            .filter-item-container.grand-child {
                .filter-item {
                    display: flex;


                    .arrow-div {
                        position: relative;
                        top: -5px;
                        width: 15px;
                        height: 15px;
                        border-bottom: 1px solid;
                        border-left: 1px solid;
                        margin-left: 7px;
                    }
                }

            }

            .filter-item-container.selected {
                background: #818589;

                .checkbox-label {
                    color: white;
                }
            }

            .left-panel {
                width: fit-content;
                border-right: 2px solid grey;
                min-height: 350px;
                max-height: 400px;
                overflow: auto;
            }

            .left-panel.remove-border {
                border-right: none;
            }

            .right-panel {
                max-height: 400px;
                overflow: auto;

                .filter-item-container {
                    min-width: 370px;
                }
            }

            .ui.checkbox label::before,
            .ui.checkbox label::after {
                width: 16px;
                height: 16px;
                border: 1px solid $bcggreenfive;
                border-radius: 0px;
            }

            .ui.checkbox label::after {
                background: $bcggreenfive;
                color: $white;
            }

            .ui.checkbox label:hover::before {
                border: 2px solid $bcggreenfive;
            }

            .checkbox-label {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: $gray--700;
                margin-left: 7px;
                cursor: pointer;
            }
        }

        .filter-search-box {
            display: flex;
            border: 1px solid $gray--500;
            background: white;
            height: 30px;
            border-radius: 2px;

            img {
                height: 15px;
                margin: 6px;
            }

            input {
                font-family: $sans-reg;
                width: 90%;
                border: none;
                outline: none;
                box-shadow: none;
            }

            input::placeholder {
                font-size: 12px;
            }

            img.cross-icon {
                cursor: pointer;
            }
        }
    }

    .footer {
        display: flex;

        div.filter-action-item {
            height: 44px;
            padding: 8px 12px;
            margin: 0 auto;

            .btn {
                text-transform: uppercase;
                margin: 0;
                text-decoration: none;
                font-size: 12px;
                font-weight: 400;
                align-items: center;
                justify-content: center;
                text-align: center;
                box-sizing: border-box;
                cursor: pointer;
                display: inline-flex;
                font-style: normal;
                border-radius: 0px;
            }

            .btn:focus {
                box-shadow: none;
            }

            .primary.button {
                width: 110px;
                height: 30px;
                background: $bcggreenfive;
                color: $white;
                border: 0px;
                float: right;
            }

            .primary.button:hover {
                background-color: $bcggreenfivehover;
            }

            .secondary.button {
                color: $bcggreenfive;
                border: 1px solid;
                height: 30px;
                margin-right: 10px;
                width: 106px;
            }

            .secondary.button:hover {
                color: $bcggreenfivehover;
            }
        }
    }

    div.filter-container {
        position: absolute;
        width: 220px;
        left: -167px;
        top: 24px;
        background: #F1EEEA;
        box-shadow: -3px -1px 7px rgba(0, 0, 0, 0.25);
        max-height: 500px;
        z-index: 2;

        div.filter-search-container {
            padding: 10px;


        }

        div.filter-list-container::-webkit-scrollbar {
            width: 5px;
            height: 10px;
        }

        div.filter-list-container {
            position: relative;
            width: 218px;
            background: #F1EEEA;
            max-height: 380px;
            overflow: auto;
            overflow-x: hidden;
            margin-top: 5px;

            div.grouped-company {
                background: #DFD7CD;
                height: 14px;
                font-size: 10px;
                line-height: 15px;
                font-weight: 700;
                color: $gray--500;
                padding-left: 10px;
            }

            div.filter-item {
                width: 218px;
                min-height: 30px;
                padding: 0px 10px;
                background: #F1EEEA;
                padding-top: 4px;

                .ui.checkbox label::before,
                .ui.checkbox label::after {
                    width: 16px;
                    height: 16px;
                    border: 1px solid $bcggreenfive;
                    border-radius: 0px;
                }

                .ui.checkbox label::after {
                    background: $bcggreenfive;
                    color: $white;
                }

                .ui.checkbox label:hover::before {
                    border: 2px solid $bcggreenfive;
                }

                .ui.checkbox.filter-checkbox label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: $gray--700;
                }
            }
        }

        .filter-separator {
            border: 1px solid $gray--400;
            margin: 6px 10px 0 10px;
        }


    }
}