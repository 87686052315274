@import "src/global-styles/colors";

.container__userChatMessage {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    max-width: 80%;
    margin-left: auto;

    .message {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        padding: 10px 20px;
        background: $gray--light;
        color: $gray--700;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
        outline: transparent solid 1px;
        margin-right: 6px;
    }
}


.container__userChatMessage>.mainheader__userProfile {
    font-size: 12px;
    margin-right: 0;
    height: 22px;
    width: 22px;
    min-width: 22px;
}