@import "src/global-styles/colors";

.examplesNavList {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    flex: 1;
    justify-content: center;
    //padding-top: 35px;
}

.example {
    word-break: break-word;
    // box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.1);

    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 9px 10px;

    img.ui.image {
        display: inline-block;
        margin-right: 10px;
    }
}

.example:hover {
    // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    //outline: 2px solid rgba(115, 118, 225, 1);
}

.exampleText {
    margin: 0;
    width: 285px;
    height: 134px;
    display: inline-block;

    p {
        font-size: 16px;
        font-weight: 700;
        background: linear-gradient(179.86deg, #0A477D 0%, #197A56 99.89%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-bottom: 5px;

        &::selection {
            -webkit-text-fill-color: $black;
        }
    }

    ul {
        margin: 10px 0 0 0;

        li {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
        }
    }
}

@media only screen and (max-height: 780px) {
    .exampleText {
        height: 134px;

        span {
            font-size: 16px;

        }
    }
}