@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.ksh_container {
    height: calc(100% - 50px);
    overflow-y: auto;

    div.filter-search-container {
        padding: 10px;

        .filter-search-box {
            display: flex;
            border: 1px solid #D4D4D4;
            background: white;
            height: 40px;
            border-radius: 4px;

            img {
                height: 15px;
                margin: 12px 6px 12px 12px;
            }

            input {
                font-family: $sans-reg;
                width: 80%;
                border: none;
                outline: none;
                box-shadow: none;
            }

            input::placeholder {
                font-size: 12px;
                color: #535353;
            }
        }
    }

    .filter-pills {
        display: flex;
        padding: 8px 16px;
        align-items: center;

        .h-pill {
            background: #F2F2F2;
            color: #323232;
            border-radius: 12px;
            padding: 4px 12px;
            margin-left: 10px;
            cursor: pointer;

            .h-pill-title {
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
            }
        }

        .h-pill.active {
            background: #323232;
            color: white;
        }

        .delete-all-text {
            color: #007b00;
            cursor: pointer;
            font-size: 10px;
            font-style: italic;
            font-weight: 700;
            text-align: right;
            margin-left: auto;
            text-decoration: underline;
        }

        .delete-all-text:hover {
            color: #005600;
        }

        .delete-all-text.disabled {
            color: #ccc;
            cursor: not-allowed;
            text-decoration: none;
        }
    }

    .hc-title {
        background: rgba(1, 1, 1, 0.04);
        padding: 8px 17px;
        color: black;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
    }

    .ksh {
        padding: 8px 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__heading {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .title {
                margin: 0 10px;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                color: #323232;
                width: calc(100% - 20px);
                cursor: pointer;
            }

            .title:hover {
                color: #197a56;
                text-decoration: underline;
            }

            .wordIconContainer {
                width: 16px;

                .wordIcon {
                    height: 12px;
                    width: 12px;
                }
            }
        }

        &__icon {
            display: none;
            gap: 7px;

            .icon {
                cursor: pointer;
                height: 16px;
                width: 16px;
            }
        }
    }

    .ksh:hover {
        //background-color: rgba(1, 1, 1, 0.1);

        .ksh__icon {
            display: flex;
        }
    }

    .no-history-container {
        margin: 150px 25px 25px;
        text-align: center;
        color: $gray--700;

        .first-line {
            font-size: 18px;
        }

        .second-line {
            font-size: 12px;
        }
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.history_modal-content {
    padding: 15px;
    width: 400px;
    text-align: center;
}


.history_modal-content h2 {
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #000000;
    text-align: left;
}

.history_modal-content p {
    margin-bottom: 20px;
    font-size: 1rem;
    color: #555;
    text-align: left;
}

.modal-buttons {
    display: flex;
    justify-content: right;
    gap: 10px;
}

.cancel-button {
    padding: 10px 15px 10px 15px;
    font-size: 14px;
    background-color: #ffffff;
    color: #000;
    cursor: pointer;
    border: 1px solid #1b1b1b;
    border-radius: 5px;
}

.delete-button {
    padding: 10px 15px 10px 15px;
    font-size: 14px;
    background-color: #f44336;
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

.modal-buttons button:hover {
    opacity: 0.8;
}