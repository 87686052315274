@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.overlay {
    position: fixed;
    background: rgb(1, 1, 1, 0.4);
    width: 100%;
    z-index: 100;
    height: 100%;
    margin-top: -50px;

    &__parent {
        position: absolute;
        background: white;
        width: 35%;
        z-index: 101;
        height: 100%;
        float: right;
        right: 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin: 15px;
        font-size: 16px;
        font-weight: 700;
        color: #333333;

        .cross-image {
            cursor: pointer;
            height: 12px;
            width: 12px;
        }
    }

    &__body {
        height: 100%;
    }
}