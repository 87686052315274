@import "src/global-styles/colors";
@import "src/mixins/_mixins";

@mixin flashingDots() {
  width: 5px;
  height: 5px;
  margin: 0px 2px 0px 2px;
  border-radius: 50%;
  background-color: $gray--light;
  display: inline-block;
}

.loading-dot-flashing {
  &__wrap {
    display: flex; 
    align-items: center;
  }

  &__item {

    background-color: $gray-D9D9D9;
    width: fit-content;
    padding: 7px;
    border-radius: 3px;

    &__first {
      @include flashingDots();
      animation: flashingAnimation 1s 0.2s ease-in infinite;
    }
  
    &__second {
      @include flashingDots();
      animation: flashingAnimation 1s 0.4s ease-in infinite;
    }
  
    &__third {
      @include flashingDots();
      animation: flashingAnimation 1s 0.6s ease-in infinite;
    }
  }

  &__info {
    @include responseLoaderInfoText();
  }
}
   
  @keyframes flashingAnimation {
    0% {
      background-color: $gray--light;
    }
    50% {
      background-color: $gray--700;
    }
    100% {
      background-color: $gray--light;
    }
  }