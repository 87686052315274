@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.bookmarks_container {
    height: calc(100% - 50px);
    overflow-y: auto;

    div.filter-search-container {
        padding: 10px 15px;

        .filter-search-box {
            display: flex;
            border: 1px solid #D4D4D4;
            background: white;
            height: 40px;
            border-radius: 4px;

            img {
                height: 15px;
                margin: 12px 6px 12px 12px;
            }

            input {
                font-family: $sans-reg;
                width: 80%;
                border: none;
                outline: none;
                box-shadow: none;
            }

            input::placeholder {
                font-size: 12px;
                color: #535353;
            }
        }
    }

    .bookmark {
        padding: 8px 15px;
        border-top: 1px solid #F2F2F2;
        border-bottom: 1px solid #F2F2F2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 60px;

        &__heading {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .title {
                margin: 0 10px;
                font-size: 12px;
                font-weight: 700;
                line-height: 18px;
                color: #323232;
                cursor: pointer;
                width: calc(100% - 20px);
            }

            .title:hover {
                color: #197a56;
                text-decoration: underline;
            }

            .wordIconContainer {
                width: 16px;

                .wordIcon {
                    height: 12px;
                    width: 12px;
                }
            }
        }

        &__icon {
            .icon {
                cursor: pointer;
                height: 16px;
                width: 16px;
            }
        }
    }

    .no-bookmarks-container {
        margin: 150px 25px 25px;
        text-align: center;
        color: $gray--700;

        .first-line {
            font-size: 18px;
        }

        .second-line {
            font-size: 12px;
        }
    }
}