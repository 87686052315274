@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

div.date-filter-container {
    position: relative;

    div.filter-container {
        position: absolute;
        width: auto;
        left: -115px;
        top: 24px;
        background: #F1EEEA;
        box-shadow: -3px -1px 7px rgba(0, 0, 0, 0.25);
        max-height: 500px;
        z-index: 2;
        padding: 10px 10px 0;

        div.filter-item {
            padding-bottom: 10px;
            width: auto;

            span {
                cursor: pointer;
            }
        }

        div.filter-item.selected {
            span {
                color: #197A56;
            }
        }

    }
}