@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.mainheader {
  &__wrap {
    padding: 6px 0 7px 0;
    background: linear-gradient(121.86deg, #0A477D 0%, #197A56 99.89%);
    backdrop-filter: blur(20px);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 50px;
    z-index: 3;
  }

  &__logo {
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;

    img.ui.image {
      height: 24px;
      display: inline-block;
      vertical-align: text-top;
      margin: 5px 17px 5px 4px;
      cursor: pointer;
    }

    &-text {
      display: inline-block;
      vertical-align: top;

      h1 {
        font-size: 10px;
        line-height: 13px;
        // font-family: 'Source Sans Pro';
        color: #A8F0B8;
        margin: 0;
      }

      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: white;
        // font-family: 'Source Sans Pro';
        display: block;
        margin: 0;
      }
    }
  }

  &__userProfile {
    float: right;
    margin-right: 20px;
    margin-top: 2px;
    height: 32px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 25px;
    }
  }

  &__bookmark {
    float: right;
    margin-right: 25px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .bookmark-icon {
      cursor: pointer;
    }
  }

  &__history {
    float: right;
    margin-right: 25px;
    margin-top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    .history-icon {
      cursor: pointer;
    }
  }

  &__compliance {
    float: right;
    margin-right: 25px;
    margin-top: 6px;
    cursor: pointer;
  }
}