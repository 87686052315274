@import "src/global-styles/colors";

.docviz-wrapper {
    .previewBtn:hover {
        text-decoration: none;
        cursor: initial !important;
    }

    .card-actions-block {
        button {
            img.ui.image {
                max-width: 24px;
            }
        }
    }

    .attachment {
        .attachTitle {
            white-space: pre-wrap;
        }
    }

    .result-card {
        box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px, rgba(0, 0, 0, 0.2) 0px 1px 3px 0px;
        border-radius: 7px;
        transition: all 0.25s ease 0s;
        position: relative;
        margin: 0px 0px 16px;
        background-color: rgb(255, 255, 255);
    }

    .result-card:hover {
        box-shadow: rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px, rgba(0, 0, 0, 0.2) 0px 3px 5px -1px;
    }

    .card-actions-block {
        position: absolute;
        right: 22px;
        top: 15px;
    }

    .result-card__top-section {
        position: relative;
    }

    .result-card-grid__body {
        padding: 10px 0px;
    }

    .result-card__title.row {
        padding-left: 35px;
        width: 90%;

        .attachTitle {
            font-size: 12px;
            width: 84%;
        }
    }

    .scrubber-image--highlighted {
        left: calc(50% - 110px) !important;
    }
}