@import "src/global-styles/colors";
@import "src/global-styles/typography";


.question-container {

    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px 15px;
    border-radius: 3px;
    background: $white;
    margin-bottom: 1px;

    &__input {
        width: 100%;
        line-height: 40px;
        border: none;
        outline: none;
        box-shadow: none;
        font-family: $sans-reg;
        background: $white;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: $gray--darkest;
        padding: 0 8px;

        &::placeholder {
            font-family: $sans-light;
            color: $gray--darkest;
            font-size: 14px;
            opacity: 1;
        }
    }

    &__icon {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &__button {
            cursor: pointer;

            svg {
                fill: #A97CDD;
                width: 23px;
            }

            .ui.image {
                width: 23px;
                height: 28px;
            }

            &--disabled {
                opacity: 0.5;
            }

        }
    }

}