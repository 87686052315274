@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";
@import "src/mixins/mixins";

body,
html {
  height: auto;
}

div ::-webkit-scrollbar {
  width: 5px;
}

.layout {
  // min-width: 1240px;
  min-width: 1170px;
  margin-top: 50px;

  .displaynone {
    display: none !important;
  }

  .main {
    /* IE11+ don't support <main> */
    position: relative;
    height: 100%;
    width: 100%;
    background-color: $gray--light;
    display: flex;
  }

  .right-sec {
    background: #fff;
    // padding-left: 310px;
    width: 100%;
  }

  .sidebar {
    height: 100vh;
    z-index: 1;
    display: flex;
    position: fixed;

    .black-bar {
      background: linear-gradient(179.86deg, #0A477D 0%, #197A56 99.89%);
      width: 50px;
      height: 100%;
      position: relative;
      padding: 10px 5px;

      .sidebar-ele {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        margin-bottom: 5px;
        cursor: pointer;

        .chat-info-icon {
          position: absolute;
          top: 30px;
          left: 30px;
        }
      }

      .active {
        background: #21BF61;
      }
    }

    .chathistory {
      display: none;
      width: 260px;
      background: #F2F2F2;
      height: 100%;
      padding: 20px 10px;

      h4 {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 20px;
        color: #323232;
      }
    }
  }

}

.two-lines-ellipsis {
  @include multilineellipsis(2);
}

.three-lines-ellipsis {
  @include multilineellipsis(3);
}