@import "src/global-styles/colors";

.overlayview-wrapper {

    .download-disabled {

        .slideSelection-bar--white .slideSlection-controls,
        .slideSelection-bar--white .slideSlection-options {
            display: none;
        }
    }

    // .expand-button {
    //     margin-left: -15px !important;
    // }
}