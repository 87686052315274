@import "src/global-styles/typography";

.sampleQueriesList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    margin: 0 auto;

    .try-searching-text {
        display: flex;
        align-items: center;
        font-size: 15px;
        padding: 10px 0;
        font-weight: bold;

        .sample-query-arrow {
            margin-right: 8px;
        }
    }

    .query-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 1200px;
        margin: 0 auto;
    }

    .query-category {
        display: flex;
        align-items: center;
        gap: 15px;
        width: 100%;
    }

    .query-header {
        display: flex;
        align-items: center;
        flex: 1;

        span {
            font-size: 14px;
            color: #707070;
            font-family: $sans-reg;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .query-buttons {
        display: flex;
        justify-content: center;
        gap: 8px;
        flex-wrap: nowrap;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }

    .query-buttonsForSearching {
        display: flex;
        justify-content: center;
        gap: 8px;
        flex-wrap: nowrap;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        padding-left: 40px;
    }

    .sample-query {
        font-size: 15px;
        border: 1px solid #535353;
        border-radius: 30px;
        height: 32px;
        white-space: nowrap;
        font-family: $sans-reg;
        cursor: pointer;
        color: #535353;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        flex-shrink: 0;
    }
}